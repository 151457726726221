<template>
  <base-list-view
    title="Сводные отчеты"
    @onRefresh="init"
    @onDelete="del"
    :dataSource="dataSource"
    :headers="headers"
    :loading="loading"
    show-actions
    pageEditName="ConsolidatedReportEdit"
    :showFilters="true"
  >
    <template slot="item.Approvement" slot-scope="{ item }">
      <div style="width: 150px">
        {{
          item.Approvement
            ? $options.filters.PersonShortName(item.Approvement)
            : ""
        }}
      </div>
    </template>
    <template v-slot:item.actions="{ item }">
      <report-activator
        :report="['ActionPlanReport', 'SummaryReport', 'FullSummaryReport']"
        :params="{ objectId: { name: 'pSummaryReport', value: item.Id } }"
      />
    </template>
  </base-list-view>
</template>

<script>
import Vue from "vue";
import ReportActivator from "@/components/report/ReportActivator.vue";
import ListEditMixin from "@/mixins/ListEditMixin";
import SummaryReportService from "@/services/SummaryReportService";
import BaseListView from "../../layouts/BaseListView.vue";
import { SummaryReportStatus } from "@/data/enums";
import { sortBy } from "@/utils/Functions";
import moment from "moment";

import Permissions from "../../data/permissions";

export default {
  name: "view-ConsolidatedReports",
  components: {
    BaseListView,
    ReportActivator,
  },

  mixins: [ListEditMixin],
  data() {
    return {
      readOnly: !this.hasPermission(Permissions.AuditOperationsEdit),
      apiService: SummaryReportService,
      loading: true,
      headers: [
        {
          text: "Год",
          value: "Year",
          width: "10%",
          cellClass: "td-minimum td5",
        },
        {
          text: "Период",
          value: "Period",
          dataType: "Period",
          width: "15%",
          align: "center",
          notGroup: true,
          displayText: (e) => Vue.filter("PeriodFormat")(e.DateIn, e.DateOut),
        },
        {
          text: "Подписывает",
          value: "Approvement",
          dataType: "object",
          displayText: (e) => this.$options.filters.PersonShortName(e),
        },
        {
          text: "Авторы",
          value: "Authors",
          dataType: "array",
          displayText: Vue.filter("PersonShortName"),
        },

        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: SummaryReportStatus,
          align: "center",
          width: "10%",
        },
      ],

      dataSource: [],
    };
  },

  created() {
    this.init();
  },
  methods: {
    DataFromServerNormalize(data) {
      data = sortBy(
        data,
        (e) => {
          return moment(e.DateOut, "DD.MM.YYYY").unix();
        },
        true
      );
      for (let i = 0; i < data.length; i++) {
        data[i].Authors = sortBy(data[i].Authors, (e) => {
          return this.$options.filters.PersonShortName(e);
        });
      }
      return data;
    },
  },
};
</script>
